<template>
  <div class="login">
    <div class="langbox">
      <!-- <van-cell center class="heing" is-link title="语言选择" @click="show = true" />
      <van-action-sheet v-model="show" :actions="actions" @select="onSelect" /> -->
    </div>
    <div class="loginContainer">
      <div class="logo">
        <van-image class="logoimg" :src="logoimg" />
      </div>
      <van-form @submit="onSubmit">
        <van-field
          v-model="phoneNum"
          name="phoneNum"
          type="number"
          :placeholder="$t('INPUTPHONE')"
        >
         <div slot="label" class="whitecolor">{{$t('CONTACTPHONE')}}</div>
        </van-field>
        <van-field
          v-model="password"
          type="password"
          name="password"
          :placeholder="$t('PASSWORD')"
        >
          <div slot="label" class="whitecolor">{{$t('PASSWORD')}}</div>
        </van-field>
        <div style="background-color: #21A0FF; padding: 30px;">
          <van-button color="" class="sub" round block type="default" native-type="submit">{{$t('SUBMIT')}}</van-button>
        </div>
      </van-form>
      <div class="footer">
        <span @click="toRegister" class="register">{{$t('REGISTER')}}</span>
        <span class="seg">|</span>
        <span @click="toForget" class="forget">{{$t('FORGET')}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from '@/api/login'
import { Toast } from 'vant'
import { setItem } from '@/utils/storage'
import './css/index.css'
export default {
  name: 'login',
  data () {
    return {
      phoneNum: '',
      password: '',
      show: false,
      actions: [
        {
          name: '中文',
          value: 'ZH'
        },
        {
          name: '泰文',
          value: 'TH'
        }
      ],
      logoimg: require('./img/qdlogo.jpg')
    }
  },
  methods: {
    async onSubmit (values) {
      // 验证
      if (!values.phoneNum) {
        Toast(this.$t('CHECKUSERNMAE'))
        return false
      } else if (!values.password) {
        Toast(this.$t('CHECKPASSWORD'))
        return false
      }
      const { data } = await login({
        phone: values.phoneNum,
        password: values.password
      })
      // 失败
      if (data.code === 500) {
        Toast(data.message)
        return false
      }
      // 成功
      if (data.code === 200) {
        this.$store.commit('setToken', data.data)
        Toast.loading({
          message: this.$t('AWAIT'),
          forbidClick: true,
          loadingType: 'spinner'
        })
        setTimeout(() => {
          this.$router.push({
            name: 'home'
          })
        }, 3000)
        if (window.isApp()) {
          return false
        } else {
          // location.href = 'http://download.qd5.cc/'
          return false
        }
      }
      // 登录接口给
      // console.log('submit', values)
    },
    toRegister () {
      this.$router.push({
        name: 'register'
      })
    },
    toForget () {
      this.$router.push({
        name: 'forget'
      })
    },
    onSelect (item) {
      this.$i18n.locale = item.value
      setItem('lang', item.value)
      console.log(item)
      this.show = false
    }
  },
  created () {
    // const that = this
    // that.loadsetRegistrationID(1)
    // const jsBridge = window.jsBridge
    // jsBridge.jiguang.setListener((msg) => {
    //   console.log(JSON.stringify(msg))
    // })
    // jsBridge.jiguang.getRegistrationID(function (rid) {
    //   // 复制到剪贴板
    //   that.loadsetRegistrationID(rid)
    //   // jsBridge.setClipboardText(rid)
    //   // jsBridge.toast("已复制")
    //   // alert(rid)
    // })
  }
}
</script>

<style scoped>
.whitecolor {
  color: #000;
}
.heing {
  height: 30px;
  width: 150px;
}
.langbox {
  position: absolute;
  top: 20px;
  left: 20px;
  /* width: 200px; */
  /* height: 30px; */
  background-color: #fff;
}
.login {
  width: 100%;
  background: #21A0FF;
  /* background: url('./img/bg.png') no-repeat center; */
  background-size: cover;
  height: 100%;
}
.loginContainer {
  /* margin: 0 auto; */
  /* background: rgb(50, 203, 250); */
  overflow: hidden;
}
.logo {
  /* border-radius: 50px; */
  box-sizing: border-box;
  width: 100%;
  height: 200px;
  /* background: #fff; */
  /* margin: 100px auto; */
}
.logoimg {
  width: 100%;
  height: 100%;
}
.sub {
  font-size: 18px;
}
.footer {
  text-align: center;
  font-size: 18px;
  margin-top: 140px;
  color: #fff;
}
.seg {
  margin: 0 30px;
}
.login >>> .van-field__control {
  font-size: 16px;
  color: #000;
}
.login >>> .van-form {
  background-color: #fff;
  /* padding-bottom: 30px; */
}
</style>
